export const onServiceWorkerUpdateReady = () => {
  window.location.reload();
};

export const onInitialClientRender = () => {
  navBarScroll();
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  navBarScroll();
};

function navBarScroll() {
  window.addEventListener("scroll", function () {
    // console.log(window.scrollY)
    if (document.querySelector(".navbar-fixed-top")) {
      if (window.scrollY > 0) {
        document.querySelector(".navbar-fixed-top").classList.add("scrolled");
        // console.log('scroll')
      } else {
        document
          .querySelector(".navbar-fixed-top")
          .classList.remove("scrolled");
      }
    }
  });
}
